import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_URL, AppConstant } from 'AppConstant'
import { APIStatus } from '../types'

interface Scan {
  id: string
}

interface FirstScan {
  id: string
  userId: string
  scanScore: number | null
  visitId: string
  createdAt: string
  updatedAt: string
}

interface Scans {
  userScan: Scan[]
  firstScan: FirstScan
}

interface ScanReport {
  userScanId: string
  spineLevel: string
  leftValue: number
  rightValue: number
  status: string
  createdAt: string
  updatedAt: string
}

interface ScanReportDetails {
  userScanDatas: ScanReport[]
  appointmentTime: string
  chiroProfilePic: string | null
  scanScore: number | null
  nextScanDueIn: number
}

interface ICurrentState {
  scans: Scans | null
  scansStatus: APIStatus
  scanById: ScanReportDetails | null
  scanByIdStatus: APIStatus
  initiateScanUpload: any
  initiateScanStatus: APIStatus
  scanUpload: any
  scanUploadStatus: APIStatus
  isScanEmpty: boolean
  isScanDue: boolean
  isPollingInProgress: boolean
}

const initialState: ICurrentState = {
  scans: null,
  scansStatus: APIStatus.idle,
  scanById: null,
  scanByIdStatus: APIStatus.idle,
  initiateScanUpload: null,
  initiateScanStatus: APIStatus.idle,
  scanUpload: null,
  scanUploadStatus: APIStatus.idle,
  isScanEmpty: false,
  isScanDue: false,
  isPollingInProgress: false,
}

export const getAllScanAsync = createAsyncThunk(
  'current/getAllScanAsync',
  async ({ patientId }: { patientId: string }, thunkAPI) => {
    const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)

    let response = await axios
      .get(`${API_URL}/patient/${patientId}/scan`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
        },
      })
      .then((res) => res)
      .catch((err) => err.response)

    if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue(response.data.data)
    } else {
      return thunkAPI.rejectWithValue(response.data.message)
    }
  },
)

export const getScanByIdAsync = createAsyncThunk(
  'current/getScanByIdAsync',
  async ({ patientId, scanId }: { patientId: string; scanId: string }, thunkAPI) => {
    const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)

    let response = await axios
      .get(`${API_URL}/patient/${patientId}/scan/${scanId}/data`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
        },
      })
      .then((res) => res)
      .catch((err) => err.response)

    if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue(response.data.data)
    } else {
      return thunkAPI.rejectWithValue(response.data.message)
    }
  },
)

export const initiateScanUploadAsync = createAsyncThunk(
  'current/initiateScanUploadAsync',
  async ({ visitId }: { visitId: any }, thunkAPI) => {
    const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)

    let response = await axios
      .post(
        `${API_URL}/scan-request`,
        { visitId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
          },
        },
      )
      .then((res) => res)
      .catch((err) => err.response)

    if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.data.message)
    }
  },
)

export const pollScanUploadStatusAsync = createAsyncThunk(
  'current/pollScanUploadStatusAsync',
  async ({ visitId }: { visitId: any }, thunkAPI) => {
    const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)

    let response = await axios
      .get(`${API_URL}/scan-request?visitId=${visitId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
        },
      })
      .then((res) => res)
      .catch((err) => err.response)

    if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.data.message)
    }
  },
)

const currentSlice = createSlice({
  name: 'current',
  initialState,
  reducers: {
    resetScans: (state) => {
      state.scans = null
    },
    resetScansStatus: (state) => {
      state.scansStatus = APIStatus.idle
    },
    resetScanByIdStatus: (state) => {
      state.scanByIdStatus = APIStatus.idle
    },
    resetIsScanEmpty: (state) => {
      state.isScanEmpty = false
    },
    setIsScanDue: (state) => {
      state.isScanDue = true
    },
    resetIsScanDue: (state) => {
      state.isScanDue = false
    },
    setIsPollingInProgress: (state) => {
      state.isPollingInProgress = true
    },
    resetIsPollingInProgress: (state) => {
      state.isPollingInProgress = false
    },
    resetScanUpload: (state) => {
      state.scanUpload = null
    },
    resetInitiateScanUpload: (state) => {
      state.initiateScanUpload = null
    },
  },
  extraReducers(builder) {
    //getAllScanAsync
    builder.addCase(getAllScanAsync.fulfilled, (state, action) => {
      state.scansStatus = APIStatus.fulfilled
      state.scans = action.payload
      if (Array.isArray(action.payload)) {
        if (action.payload.length === 0) {
          state.isScanEmpty = true
        }
      } else {
        state.isScanEmpty = false
      }
    })
    builder.addCase(getAllScanAsync.rejected, (state, action) => {
      state.scansStatus = APIStatus.rejected
    })
    builder.addCase(getAllScanAsync.pending, (state, action) => {
      state.scansStatus = APIStatus.pending
    })

    //getScanByIdAsync

    builder.addCase(getScanByIdAsync.fulfilled, (state, action) => {
      state.scanByIdStatus = APIStatus.fulfilled
      state.scanById = action.payload
    })
    builder.addCase(getScanByIdAsync.rejected, (state, action) => {
      state.scanByIdStatus = APIStatus.rejected
    })
    builder.addCase(getScanByIdAsync.pending, (state, action) => {
      state.scanByIdStatus = APIStatus.pending
    })

    //initiateScanAsync
    builder.addCase(initiateScanUploadAsync.fulfilled, (state, action) => {
      state.initiateScanStatus = APIStatus.fulfilled
      state.initiateScanUpload = action.payload
    })
    builder.addCase(initiateScanUploadAsync.rejected, (state, action) => {
      state.initiateScanStatus = APIStatus.rejected
    })
    builder.addCase(initiateScanUploadAsync.pending, (state, action) => {
      state.initiateScanStatus = APIStatus.pending
    })

    //pollScanUploadStatusAsync
    builder.addCase(pollScanUploadStatusAsync.fulfilled, (state, action) => {
      state.scanUploadStatus = APIStatus.fulfilled
      state.scanUpload = action.payload
    })
    builder.addCase(pollScanUploadStatusAsync.rejected, (state, action) => {
      state.scanUploadStatus = APIStatus.rejected
    })
    builder.addCase(pollScanUploadStatusAsync.pending, (state, action) => {
      state.scanUploadStatus = APIStatus.pending
    })
  },
})

export const {
  resetScansStatus,
  resetScanByIdStatus,
  resetScans,
  resetIsScanEmpty,
  setIsScanDue,
  resetIsScanDue,
  resetIsPollingInProgress,
  setIsPollingInProgress,
  resetScanUpload,
  resetInitiateScanUpload,
} = currentSlice.actions
export default currentSlice.reducer
