import { useState, useEffect, useRef } from 'react'
import { pollScanUploadStatusAsync, resetIsPollingInProgress, setIsPollingInProgress } from '../redux/current'
import { useAppDispatch, useAppSelector } from '../redux/store'

const useScanPolling = () => {
  const dispatch = useAppDispatch()
  // const [intervalId, setIntervalId] = useState<any>(null)
  const intervalId: any = useRef(null)
  const currentPatient = useAppSelector((state) => state.lobby.currentPatient)
  const [scanUploadSuccess, setScanUploadSuccess] = useState(false)
  const [scanUploadFailed, setScanUploadFailed] = useState(false)

  useEffect(() => {
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current)
      }
    }
  }, [])

  const startPolling = () => {
    if (intervalId.current) return
    console.log('start polling is called')
    dispatch(setIsPollingInProgress())
    const id = setInterval(() => {
      dispatch(pollScanUploadStatusAsync({ visitId: currentPatient?.visitId }))
    }, 3000)
    intervalId.current = id
  }

  const stopPolling = () => {
    console.log('stop polling is called')
    clearInterval(intervalId.current)
    intervalId.current = null
    dispatch(resetIsPollingInProgress())
  }

  function _changeScanUploadSuccess(bool: any) {
    setScanUploadSuccess(bool)
  }

  function _changeScanUploadFailed(bool: any) {
    setScanUploadFailed(bool)
  }

  return {
    startPolling,
    stopPolling,
    scanUploadFailed,
    scanUploadSuccess,
    _changeScanUploadFailed,
    _changeScanUploadSuccess,
  }
}

export default useScanPolling
