import { useEffect, useRef, useState } from 'react'
import { format } from 'date-fns'
import { getUserTimezone } from 'utils/GetUserTimezone'
import { getTodayVisitAsync, loadLobbyPreviousAsync } from '../redux/lobby'
import { useAppDispatch, useAppSelector } from '../redux/store'

export function useUpcomingAndPreviousPolling() {
  const clinicId = useAppSelector((state) => state.bed.clinic?.id)
  const intervalIdRef = useRef<any>(null)
  const dispatch = useAppDispatch()

  const startPolling = () => {
    const newDate = new Date()
    if (!intervalIdRef.current && clinicId) {
      const id = setInterval(() => {
        dispatch(loadLobbyPreviousAsync({ clinicId, date: format(newDate, 'yyyy-MM-dd') }))
        dispatch(getTodayVisitAsync({ timezone: getUserTimezone() }))
      }, 3000)

      intervalIdRef.current = id
    }
  }

  const stopPolling = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
      intervalIdRef.current = null
    }
  }

  return { startPolling, stopPolling }
}
