import { useMemo, useState } from 'react'
import { Card, Drawer, Flex, Modal } from 'antd'
import { format } from 'date-fns'
import { CardWrapper, Text, TextContent } from './notesDrawer.styled'
import Burger from '../../images/burger.svg'
import Cross from '../../images/cross.svg'
// import { useAppSelector } from 'redux/store's

const NotesDrawer = ({ onCardClick, notes }: any) => {
  const [showMenu, setShowMenu] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedNoteId, setSelectedNoteId] = useState('')
  // const currentNote = useAppSelector((state) => state.notes.currentNote)

  const dateData = useMemo(() => {
    if (notes) {
      return notes?.map((n: any) => {
        let d = new Date(n.createdAt)
        return {
          id: n.id,
          day: format(d, 'cccc'),
          date: format(d, 'MM.dd.yyyy'),
          time: format(d, 'h:mm a'),
        }
      })
    } else {
      return []
    }
  }, [notes])

  const soapNote = useMemo(() => {
    if (selectedNoteId) {
      const selectedNote = notes.find((n: any) => selectedNoteId === n.id)
      if (selectedNote) {
        return {
          subjective: selectedNote.subjective,
          objective: selectedNote.objective,
          assessment: selectedNote.assessment,
          plan: selectedNote.plan,
        }
      }
    }
    return null
  }, [selectedNoteId, notes])

  return (
    <>
      <img
        onClick={() => {
          setShowMenu(true)
        }}
        style={{ width: '46px', height: '40px' }}
        src={Burger}
      ></img>

      <Drawer
        style={{
          paddingBottom: '65px',
          touchAction: 'none',
          WebkitUserSelect: 'none',
        }}
        onClose={() => {
          setShowModal(false)
          setShowMenu(false)
          setSelectedNoteId('')
        }}
        maskClosable
        extra={
          <div
            style={{
              width: '24px',
              height: '24px',
              borderRadius: '6px',
              background: '#2A2D56',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => {
              setShowMenu(false)
              setShowModal(false)
              setSelectedNoteId('')
            }}
          >
            <img src={Cross} alt="CloseSvg"></img>
          </div>
        }
        closable={false}
        open={showMenu}
        title={'History'}
      >
        <Flex vertical gap={8} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          {dateData.map((s: any) => (
            <Card
              style={{
                border: selectedNoteId === s.id ? '1px solid #E5E7EB' : '1px solid #2A2D50',
                height: '56px',
                display: 'flex',
                width: '100%',
                alignItems: 'center',
              }}
              onClick={() => {
                setSelectedNoteId(s.id)
                setShowModal(true)
              }}
              key={s.id}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextContent style={{ position: 'absolute', left: 20 }}>{s.date}</TextContent>
                <TextContent style={{ position: 'absolute', right: 20 }}>{s.time}</TextContent>
              </div>
            </Card>
          ))}
        </Flex>
        {showModal && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '450px',
              height: 'auto',
              right: 450,
              top: 100,
              position: 'absolute',
              borderRadius: '4px',
              background: '#10123D',
              border: '1px solid #2A2D50',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              touchAction: 'none',
              WebkitUserSelect: 'none',
            }}
          >
            <div
              style={{
                width: '28px',
                height: '26px',
                borderRadius: '4px',
                position: 'absolute',
                right: '-25px',
                top: '-23px',
                background: '#2A2D56',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={Cross}
                onClick={() => {
                  setShowModal(false)
                  setSelectedNoteId('')
                }}
              ></img>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                gap: 8,
                touchAction: 'none',
              }}
            >
              <CardWrapper>
                <Text>Subjective</Text>
                <div
                  style={{
                    height: '157px',
                    overflowY: 'auto',
                    width: '100%',
                    resize: 'none',
                    padding: '25px 10px 25px 10px',
                    color: ' #e5e7eb',
                  }}
                >
                  {soapNote?.subjective}
                </div>
              </CardWrapper>
              <CardWrapper>
                <Text>Objective</Text>
                <div
                  style={{
                    height: '157px',
                    overflowY: 'auto',
                    width: '100%',
                    resize: 'none',
                    padding: '25px 10px 25px 10px',
                    color: ' #e5e7eb',
                  }}
                >
                  {soapNote?.objective}
                </div>
              </CardWrapper>
              <CardWrapper>
                <Text>Assessment</Text>
                <div
                  style={{
                    height: '157px',
                    overflowY: 'auto',
                    width: '100%',
                    resize: 'none',
                    padding: '25px 10px 25px 10px',
                    color: ' #e5e7eb',
                  }}
                >
                  {soapNote?.assessment}
                </div>
              </CardWrapper>
              <CardWrapper style={{ marginBottom: '0px' }}>
                <Text>Plan</Text>
                <div
                  style={{
                    height: '157px',
                    overflowY: 'auto',
                    width: '100%',
                    resize: 'none',
                    padding: '25px 10px 25px 10px',
                    color: ' #e5e7eb',
                  }}
                >
                  {soapNote?.plan}
                </div>
              </CardWrapper>
            </div>
          </div>
        )}
      </Drawer>

      {/* <Modal
        open={showModal}
        centered
        mask={false}
        maskClosable={false}
        style={{ right: 214, touchAction: 'none', WebkitUserSelect: 'none' }}
        footer={null}
        closeIcon={
          <div
            style={{
              width: '28px',
              height: '26px',
              borderRadius: '4px',
              position: 'absolute',
              background: '#2A2D56',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bottom: 0,
              right: 0.2,
            }}
            onClick={() => {
              setShowModal(false)
              setSelectedNoteId('')
            }}
          >
            <img src={Cross}></img>
          </div>
        }
      >
        <Flex
          vertical
          gap={8}
          style={{ width: '100%', background: '#10123D', touchAction: 'none', WebkitUserSelect: 'none' }}
        >
          <CardWrapper>
            <Text>Subjective</Text>
            <div
              style={{
                height: '100%',
                width: '100%',
                resize: 'none',
                paddingTop: '25px',
                paddingLeft: '10px',
                paddingRight: '10px',
              }}
            >
              {soapNote?.subjective}
            </div>
          </CardWrapper>

          <CardWrapper>
            <Text>Objectives</Text>
            <div
              style={{
                height: '100%',
                width: '100%',
                resize: 'none',
                paddingTop: '25px',
                paddingLeft: '10px',
                paddingRight: '10px',
              }}
            >
              {soapNote?.objective}
            </div>
          </CardWrapper>
          <CardWrapper>
            <Text>Assessment</Text>
            <div
              style={{
                height: '100%',
                width: '100%',
                resize: 'none',
                paddingTop: '25px',
                paddingLeft: '10px',
                paddingRight: '10px',
              }}
            >
              {soapNote?.assessment}
            </div>
          </CardWrapper>
          <CardWrapper>
            <Text>Plan</Text>
            <div
              style={{
                height: '100%',
                width: '100%',
                resize: 'none',
                paddingTop: '25px',
                paddingLeft: '10px',
                paddingRight: '10px',
              }}
            >
              {soapNote?.plan}
            </div>
          </CardWrapper>
        </Flex>
      </Modal> */}
    </>
  )
}

export default NotesDrawer
