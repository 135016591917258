import React, { CSSProperties, useMemo } from 'react'
import { Card, Col, Row } from 'antd'
import { EmptySpan, Header, ResOptions } from './issuesCard.styled'
import Brain from '../../images/brain.svg'
import ChiroCare from '../../images/chiro_care.svg'
import Pregnancy from '../../images/pregnancy.svg'
import Surgery from '../../images/surgery.svg'
import Tick from '../../images/tick.svg'

interface IQandA {
  type: string
  iconIdentifier: string
  optionText: string[]
  order?: string[]
  optionId: string
}

interface IIssuesCard {
  patientQuestionaries: IQandA[]
  style?: CSSProperties
}

export const IssuesCard = ({ patientQuestionaries, style }: IIssuesCard) => {
  const familyHistoryOptions = useMemo(() => {
    return patientQuestionaries
      ?.flatMap((d) => {
        if (d.iconIdentifier === 'family_health_history' && !d.optionText.includes('None')) {
          return d.optionText || []
        }
        return []
      })
      .filter(Boolean) // Filter out null or undefined values, if any
  }, [patientQuestionaries])

  const musculoskeletalOptions = useMemo(() => {
    return patientQuestionaries
      ?.flatMap((d) => {
        if (d.iconIdentifier === 'musculoskeletal' && !d.optionText.includes('None')) {
          return d.optionText || []
        }
        return []
      })
      .filter(Boolean)
  }, [patientQuestionaries])

  const medicalConditionOptions = useMemo(() => {
    return patientQuestionaries
      ?.flatMap((d) => {
        if (d.iconIdentifier === 'medical_conditions' && !d.optionText.includes('None')) {
          return d.optionText || []
        }
        return []
      })
      .filter(Boolean)
  }, [patientQuestionaries])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8, ...style }}>
      {musculoskeletalOptions.length > 0 && (
        <Card
          style={{
            display: 'flex',
            width: '100%',
            borderRadius: '4px',
            border: '1px solid #1A1D4D',
            alignItems: 'center',
          }}
          cover={
            <div style={{ width: '100%', paddingTop: 8, paddingLeft: 12, paddingRight: 12, paddingBottom: 17 }}>
              <Header>Musculoskeletal Conditions</Header>

              <Row gutter={[12, 8]} align={'stretch'} style={{ paddingLeft: '26px' }}>
                {musculoskeletalOptions.map((option, index) => (
                  <ResOptions key={index}>
                    <div key={index}>
                      <img src={Tick} style={{ marginRight: 8 }}></img>
                      {option}
                    </div>
                  </ResOptions>
                ))}
              </Row>
            </div>
          }
        ></Card>
      )}
      {medicalConditionOptions.length > 0 && (
        <Card
          style={{
            display: 'flex',
            width: '100%',
            borderRadius: '4px',
            border: '1px solid #1A1D4D',
            alignItems: 'center',
          }}
          cover={
            <div style={{ width: '100%', paddingTop: 8, paddingLeft: 12, paddingRight: 12, paddingBottom: 17 }}>
              <Header>Medical Conditions</Header>

              <Row gutter={[12, 8]} align={'stretch'} style={{ paddingLeft: '26px' }}>
                {medicalConditionOptions.map((option, index) => (
                  <ResOptions key={index}>
                    <div key={index}>
                      <img src={Tick} style={{ marginRight: 8 }}></img>
                      {option}
                    </div>
                  </ResOptions>
                ))}
              </Row>
            </div>
          }
        ></Card>
      )}
      {familyHistoryOptions.length > 0 && (
        <Card
          style={{
            display: 'flex',
            width: '100%',
            borderRadius: '4px',
            border: '1px solid #1A1D4D',
            alignItems: 'center',
          }}
          cover={
            <div style={{ width: '100%', paddingTop: 8, paddingLeft: 12, paddingRight: 12, paddingBottom: 17 }}>
              <Header>Family Health History</Header>

              <Row gutter={[12, 8]} align={'stretch'} style={{ paddingLeft: '26px' }}>
                {familyHistoryOptions.map((option, index) => (
                  <ResOptions key={index}>
                    <div key={index}>
                      <img src={Tick} style={{ marginRight: 8 }}></img>
                      {option}
                    </div>
                  </ResOptions>
                ))}
              </Row>
            </div>
          }
        ></Card>
      )}
    </div>
  )
}

export const IssueSvgCard = ({ patientQuestionaries }: IIssuesCard) => {
  const icons: { [key: string]: { [key: string]: string } } = {
    previous_chiro_care: {
      yes: ChiroCare,
    },
    pregnancy: {
      yes: Pregnancy,
    },
    surgery: {
      yes: Surgery,
    },
  }

  const iconsToShow = useMemo(() => {
    const arrayOfIcons: JSX.Element[] = []

    patientQuestionaries?.forEach((d) => {
      if (icons[d.iconIdentifier]) {
        if (
          d.iconIdentifier !== 'family_health_history' &&
          d.iconIdentifier !== 'medical_conditions' &&
          d.iconIdentifier !== 'musculoskeletal'
        ) {
          d.optionText?.forEach((i, index) => {
            if (icons[d.iconIdentifier][i]) {
              arrayOfIcons.push(
                <img
                  key={`${d.optionId}-${index}`}
                  style={{ marginRight: 16, width: '28px', height: '28px' }}
                  src={icons[d.iconIdentifier][i]}
                  alt={d.iconIdentifier}
                />,
              )
            }
          })
        }
      }
    })
    return arrayOfIcons
  }, [patientQuestionaries])

  return (
    <>
      {iconsToShow.length > 0 && (
        <Card
          cover={<div style={{ display: 'flex' }}>{iconsToShow}</div>}
          style={{
            display: 'flex',
            maxWidth: '100%',
            maxHeight: '59px',
            width: '100%',
            height: 'fit-content',
            borderRadius: '4px',
            border: '1px solid #050624',
            alignItems: 'center',
            background: 'none',
          }}
        ></Card>
      )}
    </>
  )
}
