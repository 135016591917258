import { Button as AntButton, Modal } from 'antd'
import styled, { keyframes } from 'styled-components'

export const Content = styled.div`
  height: 80vh;
  width: 100%;
  background: transparent;
  display: flex;
`
export const HeaderText = styled.div`
  color: #e5e7eb;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
export const NameText = styled.div`
  color: #e5e7eb;
  text-transform: capitalize;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const TimeStamp = styled.div`
  width: 110px;
  height: 31px;
  border-radius: 4px;
  border: 1px solid #343755;
  color: #e5e7eb;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 8px;
`

export const Label = styled.div`
  height: 30px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  color: #fffeff;
  border-radius: 4px;
  background-color: #007f7c;
`

export const LogoLabel = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`

// patient details modal content

const flash = keyframes`
  0% { background-color: #ff0000; color: #007F7C; }
  50% {background-color: #10123d; color: #007F7C;}
  100% { background-color: #ff0000; color: #e5e7eb;}
`

export const Container = styled.div``

export const Section = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  background-color: #050624;
`

export const NameWrapper = styled.div`
  width: 100%;
  // margin-top: 1px;
`
export const CardWrapper = styled.div`
  width: 100%;
  height: 97px;
  overflow-y: auto;
  border-radius: 4px;
  border: 2px solid #2a2d50;
  background: #10123d;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 16px;
`

export const Text = styled.div`
  position: absolute;
  margin-top: -13px;
  margin-left: 21px;
  padding: 12px 16px;
  width: 97px;
  background: #050624;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #2a2d50;
  border-radius: 4px;
  font-weight: 700;
  font-size: 13px;
`

export const Span = styled.span`
  width: 100%;
  display: flex;
  gap: 8px;
  color: #e5e7eb;
  text-transform: capitalize;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
export const MetricWrapper = styled.div`
  display: flex;
  gap: 11px;
  align-items: center;
  height: 48px;
  background: transparent;
  margin-top: 13px;
`
export const MetricCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: #10123d;
  border-radius: 8px;
  border: 1px solid #343755;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #007f7c;
`
export const FlashingMetricCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: #10123d;
  border-radius: 8px;
  border: 1px solid #343755;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  animation: ${flash} 1.5s infinite;
`

export const AgeBadge = styled.div`
  height: 30px;
  width: 30px;
  position: absolute;
  background: #007f7c;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 199px;
  top: 96px;
  margin-left: -8px;
  margin-top: 8px;
  z-index: 1;
  font-size: 12px;
  cursor: default;
`

export const MetricTitle = styled.span`
  color: #e5e7eb;
  font-weight: 700;
  font-size: 13px;
  margin-top: 4px;
`
export const MetricValue = styled.span`
  padding-bottom: 4px;
  font-weight: 700;
  font-size: 14px;
`
export const TreatmentTypeContainer = styled.div`
  display: flex;
  gap: 19px;
  align-items: center;
  height: 40px;
  background: transparent;
  margin-top: 13px;
  margin-bottom: 14px;
`

export const TreatmentType = styled(AntButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 162px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  color: #e5e7eb;
  background: #007f7c;
  border: 1px solid #10123d;
  padding: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`

// modal style

export const PopUp = styled(Modal)`
  width: 80% !important;
  height: 640px !important;
  background-color: #050624;
  border-radius: 4px;
  padding: 20px;
`
