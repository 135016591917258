export function getUserTimezone() {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    console.log(timezone, 'tttt')
    return timezone
  } catch (error) {
    console.error('Error getting user timezone:', error)
    return 'America/New_York'
  }
}
